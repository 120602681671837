import { isLocalhost } from 'mmfintech-commons'

const host = typeof window !== 'undefined' ? window.location.host : ''

const isDevelopment = host.indexOf('-dev.') > -1
const isSandbox = host.indexOf('-sandbox.') > -1
export const isProduction = typeof window !== 'undefined' && !isLocalhost && !isSandbox && !isDevelopment

const settings = {
  backendForLocalhost: 'https://pay-dev.jeton-app.com',
  // backendForLocalhost: 'https://pay-sandbox.jeton-app.com',
  languages: ['en', 'ja']
}

export default settings
