// noinspection SpellCheckingInspection

import styled from 'styled-components'

import bankTransferLogo from '../../../assets/methods/bank-transfer.png'
import bankyLogo from '../../../assets/methods/banky.png'
import boletoLogo from '../../../assets/methods/boleto.png'
import btvoucherLogo from '../../../assets/methods/btvoucher.png'
import creditCardLogo from '../../../assets/methods/credit-card.png'
import cryptoLogo from '../../../assets/methods/crypto.svg'
import indianNetBankingLogo from '../../../assets/methods/indian-bank.png'
import instantBankTransferLogo from '../../../assets/methods/instant-bank-transfer.svg'
import interacLogo from '../../../assets/methods/interac.png'
import jetonLogo from '../../../assets/methods/jeton.png'
import jetonWalletLogo from '../../../assets/methods/Jeton-wallet.png'
import khipuLogo from '../../../assets/methods/khipu.png'
import kingdomWalletLogo from '../../../assets/methods/kingdom-wallet.png'
import p24Logo from '../../../assets/methods/p24.png'
import pagoEfectivoLogo from '../../../assets/methods/pago-efectivo.png'
import pecFlashLogo from '../../../assets/methods/pec-flash.png'
import pixLogo from '../../../assets/methods/pix.png'
import safetyPayLogo from '../../../assets/methods/safetypay.png'
import upiLogo from '../../../assets/methods/upi.png'

export const MethodLogo = styled.span`
  position: relative;
  width: 10rem;
  height: 10rem;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  margin-bottom: 0.5rem;
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;

  &.selected {
    background-color: #fee9e6;
    border: 1px solid #fba498;
  }
  &.image-banky {
    //prettier-ignore
    background-image: url("${bankyLogo}");
  }
  &.image-btvoucher {
    //prettier-ignore
    background-image: url("${btvoucherLogo}");
  }
  &.image-crypto,
  &.image-instant_crypto {
    //prettier-ignore
    background-image: url("${cryptoLogo}");
  }

  &.image-bankwire {
    //prettier-ignore
    background-image: url("${bankTransferLogo}");
  }
  &.image-boleto {
    //prettier-ignore
    background-image: url("${boletoLogo}");
  }
  &.image-credit_card {
    //prettier-ignore
    background-image: url("${creditCardLogo}");
  }
  &.image-indian_net_banking {
    //prettier-ignore
    background-image: url("${indianNetBankingLogo}");
  }
  &.image-instant_bank_transfer,
  &.image-direct_instant_bank_transfer,
  &.image-community_instant_bank_transfer {
    //prettier-ignore
    background-image: url("${instantBankTransferLogo}");
  }
  &.image-interac {
    //prettier-ignore
    background-image: url("${interacLogo}");
  }
  &.image-jeton,
  &.image-jeton_cash {
    //prettier-ignore
    background-image: url("${jetonLogo}");
  }
  &.image-jeton_wallet {
    //prettier-ignore
    background-image: url("${jetonWalletLogo}");
  }
  &.image-khipu {
    //prettier-ignore
    background-image: url("${khipuLogo}");
  }
  &.image-kingdom_wallet {
    //prettier-ignore
    background-image: url("${kingdomWalletLogo}");
  }
  &.image-p24 {
    //prettier-ignore
    background-image: url("${p24Logo}");
  }
  &.image-pago_efectivo_bank,
  &.image-pago_efectivo_cash {
    //prettier-ignore
    background-image: url("${pagoEfectivoLogo}");
  }
  &.image-pec_flash {
    //prettier-ignore
    background-image: url("${pecFlashLogo}");
  }
  &.image-pix {
    //prettier-ignore
    background-image: url("${pixLogo}");
  }
  &.image-safetypay_bank,
  &.image-safetypay_cash {
    //prettier-ignore
    background-image: url("${safetyPayLogo}");
  }
  &.image-upi {
    //prettier-ignore
    background-image: url("${upiLogo}");
  }
`
