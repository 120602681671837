import { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { MerchantPane } from '../../components'
import { WalletCheckoutOtp } from './WalletCheckoutOtp'
import { WalletCheckoutLogin } from './WalletCheckoutLogin'
import { WalletCheckoutAccount } from './WalletCheckoutAccount'
import { WalletCheckoutPreview } from './WalletCheckoutPreview'

export const WalletCheckout = ({ sessionId, handlePay, onCancel, paymentMethod }) => {
  const { logo, session } = useSelector(
    ({ checkout: { logo, session } }) => ({
      logo,
      session
    }),
    shallowEqual
  )

  const [step, setStep] = useState(1)
  const [selectedAccount, setSelectedAccount] = useState(null)

  const handleLoginResponse = (response: any) => {
    setStep(response?.data?.challenge ? 2 : 3)
  }

  // const checkStorageAccess = async () => {
  //   const isIFrame = typeof window !== 'undefined' && window.location !== window.parent.location
  //   // const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  //   const hasAccess = await document.hasStorageAccess()
  //
  //   if (isIFrame && !hasAccess) {
  //     window.open(
  //       `${window.origin}/auth/sessionId/${sessionId}`,
  //       '_blank',
  //       'popup=1,location=0,toolbar=0,menubar=0,status=0,width=340,height=350'
  //     )
  //   }
  // }

  useEffect(() => {
    setStep(1)
    // void checkStorageAccess()
  }, [])

  return (
    <>
      {step < 4 && <MerchantPane session={session} logo={logo} />}

      {step === 1 && <WalletCheckoutLogin sessionId={sessionId} onSuccess={handleLoginResponse} onCancel={onCancel} />}
      {step === 2 && <WalletCheckoutOtp onSuccess={() => setStep(3)} onCancel={onCancel} />}
      {step === 3 && (
        <WalletCheckoutAccount
          onSuccess={() => setStep(4)}
          onCancel={onCancel}
          selectedAccount={selectedAccount}
          setSelectedAccount={setSelectedAccount}
          paymentMethod={paymentMethod}
        />
      )}
      {step === 4 && (
        <>
          <WalletCheckoutPreview onCancel={() => setStep(3)} handlePay={() => handlePay(selectedAccount.id)} />
        </>
      )}
    </>
  )
}
