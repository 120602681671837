import styled from 'styled-components'

import ADA from '../../assets/currencies/ada.png'
import AUD from '../../assets/currencies/aud.png'
import BNB from '../../assets/currencies/bnb.png'
import BRL from '../../assets/currencies/brl.png'
import BTC from '../../assets/currencies/btc.png'
import CAD from '../../assets/currencies/cad.png'
import CLP from '../../assets/currencies/clp.png'
import DOGE from '../../assets/currencies/doge.png'
import ETH from '../../assets/currencies/eth.png'
import EUR from '../../assets/currencies/eur.png'
import GBP from '../../assets/currencies/gbp.png'
import INR from '../../assets/currencies/inr.png'
import JPY from '../../assets/currencies/jpy.png'
import LTC from '../../assets/currencies/ltc.png'
import NOK from '../../assets/currencies/nok.png'
import NZD from '../../assets/currencies/nzd.png'
import PEN from '../../assets/currencies/pen.png'
import PLN from '../../assets/currencies/pln.png'
import USD from '../../assets/currencies/usd.png'
import USDC from '../../assets/currencies/usdc.png'
import USDT from '../../assets/currencies/usdt.png'
import SOL from '../../assets/currencies/sol.png'
import XRP from '../../assets/currencies/xrp.png'
import TRY from '../../assets/currencies/try.png'

export const CurrencyIconWrapper = styled.span<{ width: string; height: string }>`
  display: inline-block;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 8px;

  &.card {
    border: 1px solid #ffffff;
  }

  &.circle {
    border-radius: 50%;
  }

  &.ADA {
    //prettier-ignore
    background-image: url("${ADA}");
  }
  &.AUD {
    //prettier-ignore
    background-image: url("${AUD}");
  }
  &.BNB {
    //prettier-ignore
    background-image: url("${BNB}");
  }
  &.BRL {
    //prettier-ignore
    background-image: url("${BRL}");
  }
  &.BTC {
    //prettier-ignore
    background-image: url("${BTC}");
  }
  &.CAD {
    //prettier-ignore
    background-image: url("${CAD}");
  }
  &.CLP {
    //prettier-ignore
    background-image: url("${CLP}");
  }
  &.DOGE {
    //prettier-ignore
    background-image: url("${DOGE}");
  }
  &.ETH {
    //prettier-ignore
    background-image: url("${ETH}");
  }
  &.EUR {
    //prettier-ignore
    background-image: url("${EUR}");
  }
  &.GBP {
    //prettier-ignore
    background-image: url("${GBP}");
  }
  &.INR {
    //prettier-ignore
    background-image: url("${INR}");
  }
  &.JPY {
    //prettier-ignore
    background-image: url("${JPY}");
  }
  &.LTC {
    //prettier-ignore
    background-image: url("${LTC}");
  }
  &.NOK {
    //prettier-ignore
    background-image: url("${NOK}");
  }
  &.NZD {
    //prettier-ignore
    background-image: url("${NZD}");
  }
  &.PEN {
    //prettier-ignore
    background-image: url("${PEN}");
  }
  &.PLN {
    //prettier-ignore
    background-image: url("${PLN}");
  }
  &.SOL {
    //prettier-ignore
    background-image: url("${SOL}");
  }
  &.TRY {
    //prettier-ignore
    background-image: url("${TRY}");
  }
  &.USD {
    //prettier-ignore
    background-image: url("${USD}");
  }
  &.USDC,
  &.USDC-ERC20,
  &.USDC-TRC20 {
    //prettier-ignore
    background-image: url("${USDC}");
  }
  &.USDT,
  &.USDT-ERC20,
  &.USDT-TRC20 {
    //prettier-ignore
    background-image: url("${USDT}");
  }
  &.XRP {
    //prettier-ignore
    background-image: url("${XRP}");
  }
`
