import { shallowEqual, useSelector } from 'react-redux'

import { Button, ErrorDisplay } from 'mmfintech-portal-commons'
import {
  MerchantPaneAmountContainer,
  MerchantPaneInfoContainer,
  MerchantPaneLogoContainer,
  MerchantPaneNetAmountContainer,
  MerchantPaneWrapper,
  CoreLabelWithIcon
} from '../../../components'

import { formatMoney, stringToKebabCase, tr } from 'mmfintech-commons'

const fixTitleString = title => {
  return stringToKebabCase(title)
    .replace(/-/g, ' ')
    .split(' ')
    .map(word => {
      switch (word) {
        case 'iban':
          return 'IBAN'
        case 'swift':
          return 'SWIFT'
        default:
          return word
      }
    })
    .join(' ')
}

export const WalletCheckoutPreview = ({ handlePay, onCancel }) => {
  const { logo, session, walletPreview, walletPayError, walletPayFetching } = useSelector(
    ({ checkout: { logo, session, walletPreview, walletPayError, walletPayFetching } }) => ({
      logo,
      session,
      walletPreview,
      walletPayError,
      walletPayFetching
    }),
    shallowEqual
  )

  const { foreignTransactionId } = session || {}
  const { fee, feeCurrency, walletAmount, walletCurrency, processingAmount, processingCurrency, destination, source } =
    walletPreview || {}

  return (
    <MerchantPaneWrapper>
      <MerchantPaneAmountContainer>
        <MerchantPaneLogoContainer>
          <div>
            <div className='label'>{tr('CHECKOUT.PAYMENT.AMOUNT', 'Amount')}</div>
            <div className='value'>{formatMoney(processingAmount, processingCurrency)}</div>
            {fee > 0 ? (
              <div className='fee'>
                {tr('CHECKOUT.PAYMENT.FEE_LABEL', 'Fee')} {formatMoney(fee, feeCurrency)}{' '}
                {tr('CHECKOUT.PAYMENT.INCLUDE_LABEL', 'included')}
              </div>
            ) : null}
          </div>
          {logo && <img className='logo' src={logo} alt='' />}
        </MerchantPaneLogoContainer>
      </MerchantPaneAmountContainer>
      {fee > 0 || walletCurrency !== processingCurrency ? (
        <MerchantPaneNetAmountContainer>
          <div className='label'>{tr('CHECKOUT.PAYMENT.NET_AMOUNT', 'Net Amount')}</div>
          <div className='value'>{formatMoney(walletAmount, walletCurrency)}</div>
        </MerchantPaneNetAmountContainer>
      ) : null}

      <MerchantPaneInfoContainer>
        {source && (
          <div>
            <span className='preview-groupe-label'>{tr('CHECKOUT.PAYMENT.FROM_LABEL', 'From')}</span>
            <div className='preview-detail-information-wrapper'>
              {Object.entries(prepareObject(source)).map(([title, content]) => {
                return (
                  content &&
                  typeof content == 'string' && (
                    <CoreLabelWithIcon key={title} title={fixTitleString(title)} content={content} />
                  )
                )
              })}
            </div>
          </div>
        )}

        {destination && (
          <div className='preview-detail-container'>
            <span className='preview-groupe-label'>{tr('CHECKOUT.PAYMENT.TO_LABEL', 'To')}</span>
            <div>
              <div className='preview-detail-information-wrapper'>
                {Object.entries(prepareObject(destination)).map(([title, content]) => {
                  return (
                    content &&
                    typeof content == 'string' && (
                      <CoreLabelWithIcon key={title} title={fixTitleString(title)} content={content} />
                    )
                  )
                })}
                {foreignTransactionId && (
                  <CoreLabelWithIcon key='Reference' title='Reference' content={foreignTransactionId} />
                )}
              </div>
            </div>
          </div>
        )}

        <ErrorDisplay error={walletPayError} />

        <Button
          type='button'
          color='primary'
          className='mb-1 mt-3'
          text={tr('CHECKOUT.PAYMENT.BUTTON_REVIEW_AND_SEND', 'Review and Send')}
          onClick={handlePay}
          loading={walletPayFetching}
          data-test='button-submit'
        />
        <Button
          type='button'
          color='secondary'
          text={tr('FRONTEND.LOGIN.BUTTON_CANCEL', 'Cancel')}
          disabled={walletPayFetching}
          onClick={onCancel}
          data-test='button-back'
        />
      </MerchantPaneInfoContainer>
    </MerchantPaneWrapper>
  )
}

const prepareObject = (obj: object): object => {
  const preparedObject = Object.entries(obj).reduce((acc, [key, value]) => {
    if (typeof value === 'object') {
      Object?.entries(value)?.map(([innerKey, innerValue]) => {
        acc[innerKey] = innerValue
      })
    } else {
      acc[key] = value
    }
    return acc
  }, [])
  return rearrangeObject(preparedObject, ['holderName', 'iban'])
}

const rearrangeObject = (originalObject: Object, order: string[]): Object => {
  const newObject: Object = {}

  order.forEach(key => {
    if (key in originalObject) {
      newObject[key] = originalObject[key]
    }
  })

  Object.keys(originalObject).forEach(key => {
    if (!order.includes(key)) {
      newObject[key] = originalObject[key]
    }
  })

  return newObject
}
