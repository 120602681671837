import cn from 'classnames'
import { tr } from 'mmfintech-commons'

import { MethodLogo } from '../MethodLogo'
import { MethodButtonStyled } from './MethodButton.styled'

type MethodButtonProps = {
  name: string
  selected: boolean
  setSelected: (name: string) => void
}

export const MethodButton = ({ name, selected, setSelected }: MethodButtonProps) => (
  <MethodButtonStyled className={cn({ selected })} onClick={() => setSelected(name)} data-test='button-payment-method'>
    <MethodLogo className={cn('image-' + name.toLowerCase(), { selected })} />
    {tr('METADATA.PAYMENT_OPTIONS.' + name, name)}
  </MethodButtonStyled>
)
